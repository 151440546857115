(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("babylonjs"), require("babylonjs-loaders"), require("babylonjs-serializers"), require("babylonjs-gui"));
	else if(typeof define === 'function' && define.amd)
		define("babylonjs-inspector", ["babylonjs", "babylonjs-loaders", "babylonjs-serializers", "babylonjs-gui"], factory);
	else if(typeof exports === 'object')
		exports["babylonjs-inspector"] = factory(require("babylonjs"), require("babylonjs-loaders"), require("babylonjs-serializers"), require("babylonjs-gui"));
	else
		root["INSPECTOR"] = factory(root["BABYLON"], root["BABYLON"], root["BABYLON"], root["BABYLON"]["GUI"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__4__, __WEBPACK_EXTERNAL_MODULE__12__) {
return 